// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'
import values from 'lodash/values'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import StackGrid from 'react-stack-grid'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'A Day at CIS',
  nakedPageSlug: 'a-day-at-cis',
  pageAbstract: 'A photo gallery.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const imageQuery = graphql`
  query {
    

    image14aa: file(relativePath: { eq: "gallery/image14aa.jpg" }) {
      ...defaultImage
    }

    image14bb: file(relativePath: { eq: "gallery/image14bb.jpg" }) {
      ...defaultImage
    }

    image14cc: file(relativePath: { eq: "gallery/image14cc.jpg" }) {
      ...defaultImage
    }

    image14dd: file(relativePath: { eq: "gallery/image14dd.jpg" }) {
      ...defaultImage
    }


    image14ee: file(relativePath: { eq: "gallery/image14ee.jpg" }) {
      ...defaultImage
    }

    image14ff: file(relativePath: { eq: "gallery/image14ff.jpg" }) {
      ...defaultImage
    }

    image14gg: file(relativePath: { eq: "gallery/image14gg.jpg" }) {
      ...defaultImage
    }

    image14hh: file(relativePath: { eq: "gallery/image14hh.jpg" }) {
      ...defaultImage
    }


    image14ii: file(relativePath: { eq: "gallery/image14ii.jpg" }) {
      ...defaultImage
    }

    image14jj: file(relativePath: { eq: "gallery/image14jj.jpg" }) {
      ...defaultImage
    }

    image14kk: file(relativePath: { eq: "gallery/image14kk.jpg" }) {
      ...defaultImage
    }

    image14ll: file(relativePath: { eq: "gallery/image14ll.jpg" }) {
      ...defaultImage
    }

    image14mm: file(relativePath: { eq: "gallery/image14mm.jpg" }) {
      ...defaultImage
    }

    image14nn: file(relativePath: { eq: "gallery/image14nn.jpg" }) {
      ...defaultImage
    }















    images11: file(relativePath: { eq: "gallery/images11.jpg" }) {
      ...defaultImage
    }

    images555: file(relativePath: { eq: "gallery/images555.jpg" }) {
      ...defaultImage
    }







    images22: file(relativePath: { eq: "gallery/images22.jpg" }) {
      ...defaultImage
    }

    images666: file(relativePath: { eq: "gallery/images666.jpg" }) {
      ...defaultImage
    }


    images33: file(relativePath: { eq: "gallery/images33.jpg" }) {
      ...defaultImage
    }
    images44: file(relativePath: { eq: "gallery/images44.jpg" }) {
      ...defaultImage
    }
    images55: file(relativePath: { eq: "gallery/images55.jpg" }) {
      ...defaultImage
    }
    images66: file(relativePath: { eq: "gallery/images66.jpg" }) {
      ...defaultImage
    }
    images77: file(relativePath: { eq: "gallery/images77.jpg" }) {
      ...defaultImage
    }
    images88: file(relativePath: { eq: "gallery/images88.jpg" }) {
      ...defaultImage
    }
    images99: file(relativePath: { eq: "gallery/images99.jpg" }) {
      ...defaultImage
    }
    images111: file(relativePath: { eq: "gallery/images111.jpg" }) {
      ...defaultImage
    }
    images222: file(relativePath: { eq: "gallery/images222.jpg" }) {
      ...defaultImage
    }
    images333: file(relativePath: { eq: "gallery/images333.jpg" }) {
      ...defaultImage
    }
    images444: file(relativePath: { eq: "gallery/images444.jpg" }) {
      ...defaultImage
    }
    images777: file(relativePath: { eq: "gallery/images777.jpg" }) {
      ...defaultImage
    }
    images888: file(relativePath: { eq: "gallery/images888.jpg" }) {
      ...defaultImage
    }
    images999: file(relativePath: { eq: "gallery/images999.jpg" }) {
      ...defaultImage
    }



    image1: file(relativePath: { eq: "gallery/image1.jpg" }) {
      ...defaultImage
    }
    image2: file(relativePath: { eq: "gallery/image2.jpg" }) {
      ...defaultImage
    }
    image3: file(relativePath: { eq: "gallery/image3.jpg" }) {
      ...defaultImage
    }
    image4: file(relativePath: { eq: "gallery/image4.jpg" }) {
      ...defaultImage
    }
    image5: file(relativePath: { eq: "gallery/image5.jpg" }) {
      ...defaultImage
    }
    image6: file(relativePath: { eq: "gallery/image6.jpg" }) {
      ...defaultImage
    }
    image7: file(relativePath: { eq: "gallery/image7.jpg" }) {
      ...defaultImage
    }
    image8: file(relativePath: { eq: "gallery/image8.jpg" }) {
      ...defaultImage
    }
    image9: file(relativePath: { eq: "gallery/image9.jpg" }) {
      ...defaultImage
    }
    image10: file(relativePath: { eq: "gallery/image10.jpg" }) {
      ...defaultImage
    }
    image11: file(relativePath: { eq: "gallery/image11.jpg" }) {
      ...defaultImage
    }
    image12: file(relativePath: { eq: "gallery/image12.jpg" }) {
      ...defaultImage
    }
    image13: file(relativePath: { eq: "gallery/image13.jpg" }) {
      ...defaultImage
    }
    image14: file(relativePath: { eq: "gallery/image14.jpg" }) {
      ...defaultImage
    }
    image15: file(relativePath: { eq: "gallery/image15.jpg" }) {
      ...defaultImage
    }
    image16: file(relativePath: { eq: "gallery/image16.jpg" }) {
      ...defaultImage
    }
    image17: file(relativePath: { eq: "gallery/image17.jpg" }) {
      ...defaultImage
    }
    image18: file(relativePath: { eq: "gallery/image18.jpg" }) {
      ...defaultImage
    }
    image19: file(relativePath: { eq: "gallery/image19.jpg" }) {
      ...defaultImage
    }
    image20: file(relativePath: { eq: "gallery/image20.jpg" }) {
      ...defaultImage
    }
    image21: file(relativePath: { eq: "gallery/image21.jpg" }) {
      ...defaultImage
    }
    image22: file(relativePath: { eq: "gallery/image22.jpg" }) {
      ...defaultImage
    }
    image23: file(relativePath: { eq: "gallery/image23.jpg" }) {
      ...defaultImage
    }
    image24: file(relativePath: { eq: "gallery/image24.jpg" }) {
      ...defaultImage
    }
    image25: file(relativePath: { eq: "gallery/image25.jpg" }) {
      ...defaultImage
    }
    image26: file(relativePath: { eq: "gallery/image26.jpg" }) {
      ...defaultImage
    }
    image27: file(relativePath: { eq: "gallery/image27.jpg" }) {
      ...defaultImage
    }
    image28: file(relativePath: { eq: "gallery/image28.jpg" }) {
      ...defaultImage
    }
    image29: file(relativePath: { eq: "gallery/image29.jpg" }) {
      ...defaultImage
    }
    image30: file(relativePath: { eq: "gallery/image30.jpg" }) {
      ...defaultImage
    }
    image31: file(relativePath: { eq: "gallery/image31.jpg" }) {
      ...defaultImage
    }
    image32: file(relativePath: { eq: "gallery/image32.jpg" }) {
      ...defaultImage
    }
    image33: file(relativePath: { eq: "gallery/image33.jpg" }) {
      ...defaultImage
    }
    image34: file(relativePath: { eq: "gallery/image34.jpg" }) {
      ...defaultImage
    }
    image35: file(relativePath: { eq: "gallery/image35.jpg" }) {
      ...defaultImage
    }
    image36: file(relativePath: { eq: "gallery/image36.jpg" }) {
      ...defaultImage
    }
    image37: file(relativePath: { eq: "gallery/image37.jpg" }) {
      ...defaultImage
    }
    image38: file(relativePath: { eq: "gallery/image38.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// <Image
//   src={photo.src}
//   className={imageClass}
//   style={{ width: photo.width, height: photo.height, margin }}
//   rawWidth={photo.width}
//   rawHeight={photo.height}
//   loader={loader}
//   gradientPreset={gradientPreset}
//   key={`${photo.src}#${index}`}
// />
// <Gallery photos={photos} ImageComponent={Image} />

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const Image = ({ index, onClick, photo, margin }) => {
  return (
    <Img
      fluid={photo.childImageSharp.fluid}
      style={{ width: photo.width, height: photo.height, margin }}
    />
  )
}

/** Page */
class Page extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      alreadyRendered: false,
    }

    this.grid = undefined
    this.reRender = this.reRender.bind(this)
  }

  /** reRender */
  reRender() {
    console.log('re-render')
    const { alreadyRendered } = this.state
    if (alreadyRendered === false) {
      this.setState({ alreadyRendered: true })
      setTimeout(() => {
        this.grid.updateLayout()
        this.setState({ alreadyRendered: false })
      }, 2000)
    }
  }

  /** standard renderer */
  render() {
    let temp = values(
      pickBy(this.props.data, (value, key) => startsWith(key, 'image'))
    )
    let photos = []
    map(temp, d =>
      photos.push({
        ...d,
        src: d.childImageSharp.fluid.src,
        width: 600,
        height: 600 * d.childImageSharp.fluid.aspectRatio,
      })
    )
    const { alreadyRendered } = this.state

    return (
      <StandardPage className={pageStyle} seoData={seoData}>
        <h1 className="mask-h3">A Day at CIS</h1>
        <StackGrid
          columnWidth="33%"
          duration={480}
          gutterWidth={4}
          gutterHeight={8}
          monitorImagesLoaded={false}
          gridRef={grid => (this.grid = grid)}
          onLayout={alreadyRendered === false && this.reRender}
        >
          {map(photos, (photo, index) => (
            <Img fluid={photo.childImageSharp.fluid} />
          ))}
        </StackGrid>
      </StandardPage>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
